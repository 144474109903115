<template>
    <div class="project">
        <HeroBasic
            v-if="heroProgramsProjects?.mobileHeroImage?.url"
            :heading="title"
            :eyebrow="eyebrowText"
            :heroImageUrl="heroProgramsProjects.mobileHeroImage.url"
            :logo="heroProgramsProjects?.logo?.url"
            :pageLanguage="pageLanguage"
            isTall
            hasHeaderOffset
            class="mobile-hero"
        />
        <HeroBasic
            :heading="title"
            :eyebrow="eyebrowText"
            :heroImageUrl="heroProgramsProjects.heroImage.url"
            :logo="heroProgramsProjects?.logo?.url"
            :pageLanguage="pageLanguage"
            isTall
            hasHeaderOffset
            class="desktop-hero"
        />
        <SubnavBlock
            :navigationLinks="subNav?.navigationLinks"
            :socialNetworks="subNav?.socialNetworks"
            :button="subNav?.button"
        />
        <Tier background="grey" top-padding="standard" bottom-padding="standard">
            <div class="about-section">
                <div class="about-type">
                    <Typography variant="h4-display">{{ $t('About the Project') }}</Typography>
                    <RichTextElements :elements="about" bodyStyle="serif-small" class="dek" />
                </div>
                <AffiliationCard
                    v-if="syndications?.programs?.length === 1"
                    :eyebrow="$t('Programs')"
                    :heading="syndications.programs[0].reference?.title"
                    :dek="syndications.programs[0].reference?.dek"
                    :url="syndications.programs[0].reference?.path"
                    headingElement="h3"
                    class="card"
                />
                <div v-else-if="syndications?.programs?.length > 1" class="multiple-programs">
                    <Typography variant="label" as="h2" transform="uppercase">{{ $t('Programs') }}</Typography>
                    <ul>
                        <li>
                            <Anchor
                                v-for="program in syndications?.programs"
                                :key="program.reference?.path"
                                :url="program.reference?.path"
                                class="anchor"
                            >
                                <Typography variant="body-display-large" as="div" class="anchor-title">{{
                                    program.reference?.title
                                }}</Typography>
                                <AvatarSet :people="program.reference?.contributors" hideNames hideArchived />
                            </Anchor>
                        </li>
                    </ul>
                </div>
            </div>
        </Tier>
        <Tier
            background="grey"
            top-padding="standard"
            bottom-padding="standard"
            :eyebrow="$t('Latest work')"
            v-if="latestWork?.feed?.length || latestWork?.dynamic?.length"
        >
            <ContentFeedBlock size="100" class="desktop-content-feed" :contentFeed="latestWork" />
            <ContentFeedBlock size="50" class="mobile-content-feed" :contentFeed="latestWork" />
        </Tier>
        <Tier
            background="darkblue"
            top-padding="extra"
            bottom-padding="extra"
            :eyebrow="$t('Our team')"
            :centerEyebrow="true"
            v-if="displayExperts && nonArchivedContributors?.length"
        >
            <PersonFeedBlock :people="nonArchivedContributors" size="100" theme="dark"> </PersonFeedBlock>
        </Tier>
        <StreamTierFeed :streamTiered="streamTiered" />
        <client-only>
            <search
                class="index"
                :title="`${$t('All work from', 1, { locale: pageLanguage })} ${title}`"
                :page-language="pageLanguage"
                :index-name="`content_${pageLanguage}`"
                :filter="`syndications.projectIds = ${id}`"
                :enabled-filters="{ topics: false, regions: false, years: false, experts: false }"
                :has-filters="false"
            >
                <template #results="{ results }">
                    <ContentCardBlock
                        v-for="result in results"
                        :key="result?.id"
                        :related="transformContentSearchIndexToContentCardBlockProps(result)"
                        orientation="wide"
                        :hideDateCard="true"
                        :showPublicationDate="true"
                        context="inline"
                        class="result"
                        new-search
                    />
                </template>
            </search>
        </client-only>
    </div>
</template>
<script setup>
import transformContentSearchIndexToContentCardBlockProps from '@/utils/search/transformContentSearchIndexToContentCardBlock';
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    id: {
        type: String,
    },
    dek: {
        type: Object,
        default: () => {},
    },
    about: {
        type: Object,
        default: () => {},
    },
    contributors: {
        type: Array,
        default: () => [],
    },
    contentType: {
        type: String,
        default: '',
    },
    displayExperts: {
        type: Boolean,
    },
    heroProgramsProjects: {
        type: Object,
    },
    subNav: {
        type: Object,
    },
    latestWork: {
        type: Object,
    },
    streamTiered: {
        type: Object,
    },
    syndications: {
        type: Array,
        default: () => [],
    },
    parent: {
        type: Array,
        default: () => [],
    },
    locale: {
        type: String,
        default: '',
    },
});
const nonArchivedContributors = computed(() => {
    return props.contributors.filter((data) => !data.isArchived);
});

const eyebrowText =
    props.syndications?.programs?.length === 1
        ? `Projects - ${props.syndications?.programs?.[0]?.reference?.title}`
        : 'Projects';

const headerAttributesStore = useHeaderAttributesStore();
let GTMCenter = 'CEIP';
if (props.syndications.centers.china.syndicated === true && props.syndications.centers.china.isPrimary === true) {
    GTMCenter = 'Carnegie China';
} else if (
    props.syndications.centers.russiaEurasia.syndicated === true &&
    props.syndications.centers.russiaEurasia.isPrimary === true
) {
    GTMCenter = 'Carnegie Russia Eurasia';
} else if (
    props.syndications.centers.middleEast.syndicated === true &&
    props.syndications.centers.middleEast.isPrimary === true
) {
    GTMCenter = 'Carnegie Middle East Center';
} else if (
    props.syndications.centers.europe.syndicated === true &&
    props.syndications.centers.europe.isPrimary === true
) {
    GTMCenter = 'Carnegie Europe';
} else if (
    props.syndications.centers.india.syndicated === true &&
    props.syndications.centers.india.isPrimary === true
) {
    GTMCenter = 'Carnegie India';
}
const GTMLang = props.locale;
const GTMTitle = props.title;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Project Page',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': GTMTitle,
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
</script>
<style lang="scss" scoped>
.dek {
    opacity: 0.8;
}

.about-section {
    @include content-area-padding;

    @include media-query(full) {
        @include grid($layout: '50');

        .card {
            grid-column: 2 / span 1;
        }
    }
}

.about-type {
    @include media-query(mobile) {
        margin-bottom: 3em;
    }
}

.desktop-content-feed {
    @include media-query(phone) {
        display: none !important;
    }
}

.mobile-content-feed {
    @include media-query(not-phone) {
        display: none !important;
    }
}

.multiple-programs {
    max-width: 75%;
    margin: 0 auto;
}

.anchor {
    margin-top: 1.2rem;
    display: flex;
    min-height: 3.6rem;
    width: 100%;
    justify-content: space-between;
    gap: 1.2rem;
    border-bottom: 1px solid palette(border-light);

    .anchor-title {
        color: color(blue, default, 0.75);
    }
}
</style>
